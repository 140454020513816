import React, { FC, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import {
  canUseWiringTypesAnswerToBoolean,
  InstructionNextActionType,
  ReducedSupplierRegistrationProcess,
  RegistrationType,
  SupplierRegistrationBankAccountFormFields,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
  SupplierRegistrationProcessRequest,
  SupplierRegistrationProgressStep,
  ValidationProcessSteps,
} from '@supplierRegistration/domain/supplierRegistration';
import SupplierRegistrationBankAccountDetails from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationBankAccountDetails';
import SupplierRegistrationCompanyInformation from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationCompanyInformation';
import { Trans, useTranslation } from 'react-i18next';
import useInfraStores from '@app/hooks/useInfraStores';
import WelcomeSupplier from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/WelcomeSupplier';
import TransitionLoader from '@app/components/TransitionLoader';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';
import { trimToFallback, trimToNull, trimToUndefined } from '@app/utils/stringUtils';
import {
  AllLegalIdTypeForCompany,
  getLegalIdTypesOfCountryForIndividual,
} from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import browserHistory from '@app/utils/browserHistory';
import { extractLogErrorIdFromError } from '@app/libs/request';
import { showContentOnlyModal } from '@app/components/Modal';
import ErrorModal from '@app/components/ErrorModal';
import BackButton from '@supplierRegistration/components/BackButton';
import { getDialCodeByCountryCode } from '@app/domain/countries';
import { convertDatePickerValueToEpoch } from '@app/components/inputs/DatePicker';
import LogoCarousel from '@app/components/logoCarousel/LogoCarousel';
import useTheme from '@app/hooks/useTheme';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { itemOrFirst } from '@app/utils/arrayUtils';
import Card from '@app/components/card/Card';
import ErrorSVG from '@supplierRegistration/images/icon-page-error.svg';
import SVG from '@app/components/SVG';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';
import {
  AsyncInitiateValidationMaskedData,
  SupplierRegistrationAsyncInitiateValidationProcessRequest,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { isDefined } from '@app/utils/utils';
import useIsMounted from '@app/hooks/useIsMounted';
import {
  SupplierRegistrationProcessFormType,
  SupplierRegistrationProcessInstructionType,
  SupplierRegistrationProcessInstructionTypesSubsets,
} from '@app/domain/commonSupplierRegistration';
import {
  isSuitableForOpenBanking,
  isSuitableForOpenBankingAsync,
  validateFinicityAccountAsync,
} from '@supplierRegistration/services/openBankingService';
import SupplierRegistrationOpenBankingSelector from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationOpenBankingSelector';
import config from '@app/config';
import Log from '@app/libs/logger';

const SupplierRegistrationFormPage: FC = observer(() => {
  const { navigationStore, languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationFormStore, supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { t } = useTranslation();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const isIpsMode = navigationStore.isIpsPage;
  const { brand } = useTheme<SupplierRegistrationMode>();
  const location = useLocation();
  const isMountedRef = useIsMounted();

  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const additionalLogos = brand?.additionalLogos;

  function onSupplierDetailsFormSubmit(supplierValues: SupplierRegistrationCompanyInfoFormFields): void {
    saveSupplierDetailsValues(supplierValues);
  }

  function saveLoadedValues(values: SupplierRegistrationFormValuesState): void {
    supplierRegistrationFormStore.setSupplierRegistrationValues({
      supplierValues: values.supplierValues,
      accountValues: values.accountValues,
    });
    nextStep();
  }

  function saveSupplierDetailsValues(supplierValues: SupplierRegistrationCompanyInfoFormFields): void {
    supplierRegistrationFormStore.setSupplierRegistrationValues({
      supplierValues,
      accountValues: supplierRegistrationFormStore.currentSupplierRegistrationValues.accountValues,
    });
  }

  function saveAccountDetailsValues(newAccountValues: SupplierRegistrationBankAccountFormFields): void {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues) {
      supplierRegistrationFormStore.setSupplierRegistrationValues({
        supplierValues: supplierRegistrationFormStore.currentSupplierRegistrationValues.supplierValues,
        accountValues: { ...newAccountValues, hasAgreed: newAccountValues.hasAgreed ?? accountValues?.hasAgreed },
      });
    }
  }

  async function callAsyncPreRegistration(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    ref: string,
    asyncMaskedData: AsyncInitiateValidationMaskedData,
    captchaToken: string | null | undefined,
  ): Promise<void> {
    const payload = transformFormFieldsToSupplierRegistrationAsyncRequest(
      companyInfoValues,
      bankAccountValues,
      ref,
      asyncMaskedData,
    );

    await supplierRegistrationFormStore.preRegistrationRequestFromAsyncInitiateValidation(
      payload,
      asyncMaskedData.id,
      captchaToken,
    );
  }

  async function callPreRegistrationRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ): Promise<void> {
    try {
      const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

      let captchaToken: string | null | undefined = undefined;
      if (shouldUseCaptcha) {
        try {
          captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
        } catch (e) {
          Log.event('Cannot load captcha token, sending pre registration request without captcha header', e);
        }
      }

      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        await callAsyncPreRegistration(
          companyInfoValues,
          bankAccountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
          captchaToken,
        );
      } else {
        const payload = transformRegularValidationProcessRequest(companyInfoValues, bankAccountValues);
        await supplierRegistrationFormStore.preRegistrationRequest(payload, captchaToken);
      }

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }
    } catch (e) {
      Log.event('Error while calling pre-registration api', e);
    } finally {
      if (isMountedRef.current) {
        setIsRequestLoading(false);
      }
    }
  }

  async function onAccountDetailsFormSubmit(accountValuesToSave: SupplierRegistrationBankAccountFormFields): Promise<void> {
    saveAccountDetailsValues(accountValuesToSave);

    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues && accountValues) {
      await callPreRegistrationRequest(supplierValues, accountValues);
    }

    const useFinicityOpenBankingForRef =
      (supplierRegistrationCustomerDataStore.currentReference &&
        supplierRegistrationCustomerDataStore.currentConfiguration.result?.useFinicityOpenBanking) ||
      !supplierRegistrationCustomerDataStore.currentReference;

    if (config.featureFlags?.finicityOpenBanking && useFinicityOpenBankingForRef) {
      if (await checkIsSuitableForOpenBanking()) {
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.useOpenBanking);

        return;
      }
    }

    await storeValidationProcess();
  }

  async function storeValidationProcess(): Promise<void> {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues && accountValues) {
      await storeValidationProcessRequest(supplierValues, accountValues);
    }
  }

  function nextStep(): void {
    switch (supplierRegistrationFormStore.currentValidationProcessStep) {
      case ValidationProcessSteps.welcome:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.companyInformation);
        break;
      case ValidationProcessSteps.companyInformation:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.bankAccountDetails);
        break;
    }
  }

  function previousStep(): void {
    switch (supplierRegistrationFormStore.currentValidationProcessStep) {
      case ValidationProcessSteps.companyInformation:
        if (!supplierRegistrationFormStore.skipWelcome) {
          supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.welcome);
        }
        break;
      case ValidationProcessSteps.bankAccountDetails:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.companyInformation);
        break;
      case ValidationProcessSteps.useOpenBanking:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.bankAccountDetails);
        break;
    }
  }

  const configuration = supplierRegistrationCustomerDataStore.currentConfiguration.result ?? null;
  const isAsyncProcessErrored = !!supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedDataLoadError;

  function renderBackButton(): ReactNode {
    const currentStep = supplierRegistrationFormStore.currentValidationProcessStep;

    if (
      currentStep === ValidationProcessSteps.bankAccountDetails ||
      (!supplierRegistrationFormStore.skipWelcome && currentStep === ValidationProcessSteps.companyInformation) ||
      currentStep === ValidationProcessSteps.useOpenBanking
    ) {
      return <BackButton onClick={previousStep}>{t('supplierValidation.supplierRegister.back')}</BackButton>;
    }
  }

  function getValidationStepComponent(step: ValidationProcessSteps): ReactNode {
    if (isAsyncProcessErrored) {
      return (
        <ErroContainer>
          <ErrorMainCard>
            <StyledSVG image={ErrorSVG} accessibilityLabel='error' />
            <Trans i18nKey='supplierValidation.supplierRegister.errors.invalidLink' values={{ customerName }}>
              This registration link is expired or invalid. For assistance, please contact {{ customerName }} or nsKnox at
              <MailTo href='mailto:support@nsknox.net'>support@nsknox.net</MailTo>
            </Trans>
          </ErrorMainCard>
        </ErroContainer>
      );
    }

    const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

    switch (step) {
      case ValidationProcessSteps.welcome:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.Welcome);
        return (
          <WelcomeSupplier
            configuration={configuration}
            nextStep={nextStep}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveLoadedValues}
            isIpsMode={isIpsMode}
          />
        );
      case ValidationProcessSteps.companyInformation:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.Registration);
        return (
          <SupplierRegistrationCompanyInformation
            configuration={configuration}
            nextStep={nextStep}
            onFormSubmit={onSupplierDetailsFormSubmit}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveSupplierDetailsValues}
            isIpsMode={isIpsMode}
          />
        );
      case ValidationProcessSteps.bankAccountDetails:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.BankAccountDetails);
        return (
          <SupplierRegistrationBankAccountDetails
            configuration={configuration}
            nextStep={nextStep}
            onFormSubmit={onAccountDetailsFormSubmit}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveAccountDetailsValues}
            isIpsMode={isIpsMode}
            isIndividual={supplierRegistrationFormStore.isIndividual}
          />
        );
      case ValidationProcessSteps.useOpenBanking:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.BankAccountDetails);
        return (
          <SupplierRegistrationOpenBankingSelector
            setLoading={setIsRequestLoading}
            onRegularSelect={storeValidationProcess}
            configuration={configuration}
            nextStep={nextStep}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            isIpsMode={isIpsMode}
            validateFinicityAccountAsync={
              currentAsyncProcessMaskedData?.isResolved() && configuration?.ref ? validateFinicityAccountAsyncHandler : undefined
            }
          />
        );
    }
  }

  async function validateFinicityAccountAsyncHandler(customerId: string): Promise<void> {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (!supplierValues || !accountValues) {
      throw new Error('Missing supplier or account values');
    }

    const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

    let captchaToken: string | null | undefined = undefined;
    if (shouldUseCaptcha) {
      try {
        captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
      } catch (e) {
        Log.event('Cannot load captcha token, sending request without captcha header', e);
      }
    }

    const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

    if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
      const asyncInitiateValidationProcessRequest = transformFormFieldsToSupplierRegistrationAsyncRequest(
        supplierValues,
        accountValues,
        configuration?.ref,
        currentAsyncProcessMaskedData.result,
      );

      await validateFinicityAccountAsync(
        asyncInitiateValidationProcessRequest,
        currentAsyncProcessMaskedData.result.id,
        customerId,
        captchaToken,
      );

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }
    } else {
      throw new Error('Missing async properties for async process');
    }
  }

  function getIsIndividual(): boolean {
    return (
      supplierRegistrationFormStore.currentSupplierRegistrationValues.supplierValues?.registrationType ===
      RegistrationType.individual
    );
  }

  function extractRegistrationIdFromUrl(): string | null | undefined {
    const { id } = qs.parse(location?.search);

    return itemOrFirst(id);
  }

  function getNextAction(displayInstructionType: SupplierRegistrationProcessInstructionType): InstructionNextActionType {
    return SupplierRegistrationProcessInstructionTypesSubsets.bankValidation.includes(displayInstructionType)
      ? InstructionNextActionType.paymentKnox
      : InstructionNextActionType.payee;
  }

  const checkIsSuitableForOpenBanking = async (): Promise<boolean> => {
    let isSuitable = false;
    try {
      setIsRequestLoading(true);

      const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

      if (!supplierValues || !accountValues) {
        return false;
      }

      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        const asyncInitiateValidationProcessRequest = transformFormFieldsToSupplierRegistrationAsyncRequest(
          supplierValues,
          accountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
        );

        isSuitable = await isSuitableForOpenBankingAsync(
          asyncInitiateValidationProcessRequest,
          currentAsyncProcessMaskedData.result.id,
        );
      } else {
        const supplierRegistrationProcessRequest = transformRegularValidationProcessRequest(supplierValues, accountValues);

        const validationType = isIpsMode
          ? SupplierRegistrationProcessFormType.incomingPaymentSecurity
          : SupplierRegistrationProcessFormType.regularSupplierValidation;

        isSuitable = await isSuitableForOpenBanking(supplierRegistrationProcessRequest, validationType);
      }
    } catch (e) {
      Log.event('Error while checking open banking suitable', e);
      isSuitable = false;
    } finally {
      setIsRequestLoading(false);
    }

    return isSuitable;
  };

  async function storeValidationProcessRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ): Promise<void> {
    setIsRequestLoading(true);

    try {
      const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

      let captchaToken: string | null | undefined = undefined;
      if (shouldUseCaptcha) {
        try {
          captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
        } catch (e) {
          Log.event('Cannot load captcha token, sending request without captcha header', e);
        }
      }

      const companyEmail = companyInfoValues.email.trim();

      let processResponse: ReducedSupplierRegistrationProcess;
      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        processResponse = await storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
          companyInfoValues,
          bankAccountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
          captchaToken,
        );

        const iFrameMessage = {
          type: 'nsknoxSupplierRegistrationFinishedSuccessfully',
          data: {
            registrationNumber: processResponse.registrationNumber,
            instruction: {
              nextAction: getNextAction(processResponse.displayInstructionType),
            },
          },
        };

        window.parent.postMessage(JSON.stringify(iFrameMessage), '*');
      } else {
        processResponse = await storeRegularValidationProcessRequest(companyInfoValues, bankAccountValues, captchaToken);
      }

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }

      supplierRegistrationFormStore.cleanSupplierRegistrationStoreValues();

      browserHistory.push(
        navigationStore.generateAccountValidationAfterForm(
          false,
          companyEmail,
          null,
          configuration?.ref ?? null,
          processResponse.displayInstructionType,
          supplierRegistrationFormStore.skipWelcome,
        ),
      );
    } catch (e) {
      const errorLogId = extractLogErrorIdFromError(e);

      const bodyContent = (
        <div>
          <Trans
            i18nKey='supplierValidation.supplierRegister.errors.anErrorOccurredDuringTheRegistration'
            values={{ errorLogId }}
          >
            An error occurred during the registration. Please contact us at <BlueText>accountvalidation@nsknox.net</BlueText> for
            assistance.
          </Trans>
          {errorLogId && ` ${t('supplierValidation.supplierRegister.errors.referToErrorCode')} ${errorLogId}`}
        </div>
      );

      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent={t('general.somethingWentWrong')}
          bodyContent={bodyContent}
          okButtonText={t('general.ok')}
          onDone={onDone}
        />
      ));
    } finally {
      if (isMountedRef.current) {
        setIsRequestLoading(false);
      }
    }
  }

  function transformRegularValidationProcessRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ): SupplierRegistrationProcessRequest {
    const phoneNumber = calculateFormPhoneNumber(companyInfoValues.phoneCountryCode, companyInfoValues.phoneNumber);
    if (!phoneNumber) {
      throw new Error(
        `cannot calculate, phone country code = ${companyInfoValues.phoneCountryCode}, phone number = ${companyInfoValues.phoneNumber}`,
      );
    }

    return {
      isIndividual: getIsIndividual(),
      hasAgreed: bankAccountValues.hasAgreed,
      fullName: companyInfoValues.fullName.trim(),
      email: companyInfoValues.email.trim(),
      phoneNumber: phoneNumber,
      companyName: companyInfoValues.companyName.trim(),
      additionalCompanyName: trimToNull(companyInfoValues.additionalCompanyName),
      address: {
        addressDetails: {
          address: companyInfoValues.companyAddress.trim(),
          city: companyInfoValues.companyAddressCity.trim(),
          countryCode: companyInfoValues.companyAddressCountry.trim(),
          state: trimToNull(companyInfoValues.companyAddressState),
          zipCode: companyInfoValues.companyAddressZipCode.trim(),
        },
      },
      legalId: trimToUndefined(companyInfoValues.leiValue),
      legalIdType: calculateLegalIdType(companyInfoValues) ?? undefined,
      countryCode: companyInfoValues.country.trim(),
      companyWebsite: trimToUndefined(companyInfoValues.website),
      referringCustomer: isIpsMode ? undefined : companyInfoValues.referrer?.trim(),
      dateOfBirth: convertDatePickerValueToEpoch(companyInfoValues.dateOfBirth),
      accountBankCountryCode: bankAccountValues.accountBankCountryCode.trim(),
      currency: bankAccountValues.currency.trim(),
      accountDetails: {
        swiftCode: trimToUndefined(bankAccountValues.swiftCode),
        bankCode: trimToUndefined(bankAccountValues.bankCode),
        branchCode: trimToUndefined(bankAccountValues.branchCode),
        accountNumber: trimToFallback(bankAccountValues.accountNumber, ''),
      },
      abaRouting: trimToUndefined(bankAccountValues.abaRouting),
      furtherCredit: trimToUndefined(bankAccountValues.furtherCredit),
      organizationReferenceId: isIpsMode ? undefined : trimToUndefined(configuration?.ref),
      canUseAch: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseAch),
      canUseEft: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseEft),
      registrationId: trimToUndefined(extractRegistrationIdFromUrl()),
      accountType: bankAccountValues.accountType,
      hasAgreedIsrael: bankAccountValues.hasAgreedIsrael,
      bankName: bankAccountValues.bankName,
    };
  }

  async function storeRegularValidationProcessRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> {
    const payload = transformRegularValidationProcessRequest(companyInfoValues, bankAccountValues);

    return await supplierRegistrationFormStore.storeValidationProcessRequest(
      payload,
      isIpsMode,
      languageStore.selectedLocale,
      captchaToken,
    );
  }

  function calculateLegalIdType(companyInfoValues: SupplierRegistrationCompanyInfoFormFields): string | null {
    if (getIsIndividual()) {
      return trimToNull(getLegalIdTypesOfCountryForIndividual(companyInfoValues.country.trim()));
    }

    if (companyInfoValues.leiType !== AllLegalIdTypeForCompany.Other) {
      return trimToNull(companyInfoValues.leiType);
    }

    return trimToNull(companyInfoValues.leiTypeOtherValue);
  }

  function transformFormFieldsToSupplierRegistrationAsyncRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    ref: string,
    asyncMaskedData: AsyncInitiateValidationMaskedData,
  ): SupplierRegistrationAsyncInitiateValidationProcessRequest {
    let phoneNumber: string | null;
    if (asyncMaskedData.contactInfo.phoneNumber) {
      phoneNumber = null;
    } else {
      phoneNumber = calculateFormPhoneNumber(companyInfoValues.phoneCountryCode, companyInfoValues.phoneNumber);
      if (!phoneNumber) {
        throw new Error(
          `cannot calculate, phone country code = ${companyInfoValues.phoneCountryCode}, phone number = ${companyInfoValues.phoneNumber}`,
        );
      }
    }

    return {
      isIndividual: nullInCaseValueComesFromAsync(asyncMaskedData.payee.isIndividual, getIsIndividual()),
      hasAgreed: bankAccountValues.hasAgreed,
      fullName: nullInCaseValueComesFromAsync(asyncMaskedData.contactInfo.fullName, companyInfoValues.fullName.trim()),
      email: nullInCaseValueComesFromAsync(asyncMaskedData.contactInfo.email, companyInfoValues.email.trim()),
      phoneNumber: phoneNumber,
      companyName: nullInCaseValueComesFromAsync(asyncMaskedData.payee.name, companyInfoValues.companyName.trim()),
      additionalCompanyName: trimToNull(companyInfoValues.additionalCompanyName),
      address: {
        addressDetails: nullInCaseValueComesFromAsync(asyncMaskedData.payee.address, {
          address: trimToNull(companyInfoValues.companyAddress),
          city: trimToNull(companyInfoValues.companyAddressCity),
          countryCode: trimToNull(companyInfoValues.companyAddressCountry),
          state: trimToNull(companyInfoValues.companyAddressState),
          zipCode: trimToNull(companyInfoValues.companyAddressZipCode),
        }),
      },
      legalId: nullInCaseValueComesFromAsync(asyncMaskedData.payee.legalIdentifier, trimToNull(companyInfoValues.leiValue)),
      legalIdType: nullInCaseValueComesFromAsync(asyncMaskedData.payee.legalIdentifier, calculateLegalIdType(companyInfoValues)),
      countryCode: nullInCaseValueComesFromAsync(asyncMaskedData.payee.countryCode, companyInfoValues.country.trim()),
      companyWebsite: nullInCaseValueComesFromAsync(asyncMaskedData.payee.website, trimToNull(companyInfoValues.website)),
      dateOfBirth: convertDatePickerValueToEpoch(companyInfoValues.dateOfBirth) ?? null,
      accountBankCountryCode: nullInCaseValueComesFromAsync(
        asyncMaskedData.account.countryCode,
        bankAccountValues.accountBankCountryCode.trim(),
      ),
      currency: nullInCaseValueComesFromAsync(asyncMaskedData.account.currency, bankAccountValues.currency.trim()),
      accountDetails: {
        swiftCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.swiftCode, trimToNull(bankAccountValues.swiftCode)),
        bankCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.bankCode, trimToNull(bankAccountValues.bankCode)),
        branchCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.branchCode, trimToNull(bankAccountValues.branchCode)),
        accountNumber: nullInCaseValueComesFromAsync(
          asyncMaskedData.account.accountNumber,
          trimToNull(bankAccountValues.accountNumber),
        ),
        iban: nullInCaseValueComesFromAsync(
          asyncMaskedData.account.iban,
          trimToNull(bankAccountValues.ibanForAsyncInitiateValidation),
        ),
      },
      abaRouting: trimToNull(bankAccountValues.abaRouting),
      furtherCredit: trimToNull(bankAccountValues.furtherCredit),
      organizationReferenceId: ref,
      canUseAch: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseAch) ?? null,
      canUseEft: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseEft) ?? null,
      registrationId: trimToNull(extractRegistrationIdFromUrl()),
      accountType: bankAccountValues.accountType ?? null,
    };
  }

  async function storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    ref: string,
    asyncMaskedData: AsyncInitiateValidationMaskedData,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> {
    const payload = transformFormFieldsToSupplierRegistrationAsyncRequest(
      companyInfoValues,
      bankAccountValues,
      ref,
      asyncMaskedData,
    );

    return await supplierRegistrationFormStore.storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
      payload,
      asyncMaskedData.id,
      languageStore.selectedLocale,
      captchaToken,
    );
  }

  function calculateFormPhoneNumber(phoneCountryCode: string, localPhoneNumber: string): string | null {
    const dialCode = getDialCodeByCountryCode(phoneCountryCode);
    if (!dialCode) {
      return null;
    }

    return joinNationalPhoneAndDialCode(dialCode.toString(), localPhoneNumber);
  }

  function nullInCaseValueComesFromAsync<T>(asyncDataFieldExistenceIndication: unknown, formField: T): T | null {
    if (isDefined(asyncDataFieldExistenceIndication)) {
      return null;
    }

    return formField;
  }

  return (
    <TransitionLoader loading={isRequestLoading}>
      <PageWithHeader paddingTop='none' widthReactive>
        <PageContainer>
          <AboveCardSpace>
            {renderBackButton()}
            {supplierRegistrationFormStore.currentValidationProcessStep === ValidationProcessSteps.welcome && (
              <LogoCarousel logos={additionalLogos} maxLogosToKeepStatic={5} />
            )}
          </AboveCardSpace>
          {getValidationStepComponent(supplierRegistrationFormStore.currentValidationProcessStep)}
        </PageContainer>
      </PageWithHeader>
    </TransitionLoader>
  );
});

export default SupplierRegistrationFormPage;

const PageContainer = styled.div`
  max-width: 1000px;
  align-self: center;
`;

const BlueText = styled.span`
  font-weight: bold;
  color: var(--accent-blue-600);
`;

const AboveCardSpace = styled.div`
  height: 90px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
`;

const ErroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMainCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${BodyRegularStartTransparentBlack900.css};
  padding: 30px clamp(20px, 5vw, 50px) 30px;
  max-width: 500px;
`;

const MailTo = styled.a`
  font-weight: bold;
`;

const StyledSVG = styled(SVG)`
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
`;
